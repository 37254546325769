
import { defineComponent } from "vue";
import { Template } from "@/utils/components/Template";

export default defineComponent({
  data() {
    return {};
  },

  props: {
    component: {
      type: Object as () => Template,
      required: true
    }
  }
});
