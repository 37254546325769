
import { defineComponent } from "vue";
import {
  actions,
  actionIDs,
  actionFromJson
} from "@/utils/manager/ActionManager";
import ComponentList from "@/components/ComponentList.vue";

import { toggleVis, invisibleIDs } from "@/utils/manager/ComponentManager";
import { Action } from "../utils/actions/Action";
import { settings } from "../utils/manager/SettingsManager";
import { devMode, selection } from "../utils/manager/WorkspaceManager";
import { vueRef } from "../utils/VueRef";

export default defineComponent({
  components: { ComponentList },

  data() {
    return {
      settings,
      actionTarget: null as null | Action[],
      copiedAction: null as null | string,
      actions,
      actionIDs,
      toggleVis,
      invisibleIDs: vueRef(invisibleIDs),
      selection: vueRef(selection),
      devMode: vueRef(devMode)
    };
  },

  mounted() {
    document.addEventListener("click", this.checkClose, { capture: true });
  },

  unmounted() {
    document.removeEventListener("click", this.checkClose, { capture: true });
  },

  methods: {
    checkDelete(action: Action) {
      if (action == this.selection?.action) {
        this.selection.action = null;
      }
    },

    addActionToTree(data: { event: MouseEvent; anchor: Action[] }) {
      this.showActionAddMenu(data.event, data.anchor);
    },

    addNewAction(key: string) {
      if (this.selection && this.actionTarget) {
        const nAction = actions[key].generator(this.selection.component);
        this.actionTarget.push(nAction);
        this.selection.action = nAction;
      }
    },

    pasteAction() {
      if (this.selection && this.copiedAction && this.actionTarget) {
        const nAction = actionFromJson(JSON.parse(this.copiedAction));
        this.actionTarget.push(nAction);
        this.selection.action = nAction;
      }
    },

    showActionAddMenu(ev: MouseEvent, target?: Action[]) {
      this.actionTarget = target || this.selection?.component.clickAction!;

      const menu = this.$refs.actionAddMenu as HTMLElement;
      menu.style.display = "block";
      setTimeout(() => {
        menu.style.top = ev.y + 10 + "px";
        menu.style.left = ev.x - menu.offsetWidth / (target ? 1 : 2) + "px";
      }, 10);
    },

    checkClose(ev: MouseEvent) {
      const menuAction = this.$refs.actionAddMenu as HTMLElement;
      if (menuAction && ev.target != menuAction)
        menuAction.style.display = "none";
    },

    copyID() {
      const input = this.$refs.idInput as HTMLInputElement;
      const icon = this.$refs.copyIcon as HTMLElement;

      input.select();
      input.setSelectionRange(0, 99999);
      document.execCommand("copy");

      icon.innerText = "assignment_turned_in";

      setTimeout(() => (icon.innerText = "content_copy"), 1000);
    }
  }
});
