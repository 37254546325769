
import { defineComponent } from "vue";
import { history, redo, undo } from "../utils/manager/HistoryManager";
import { settings } from "../utils/manager/SettingsManager";

export default defineComponent({
  data() {
    return {
      history,
      redo,
      undo,
      settings
    };
  }
});
