
import { defineComponent } from "vue";
import { MoneyCheck } from "@/utils/checks/MoneyCheck";
import { Template } from "@/utils/components/Template";

export default defineComponent({
  data() {
    return {
      inputTransformer: Template.inputTransformer
    };
  },

  watch: {
    "action.amount": {
      handler(val) {
        if (val === "") this.action.amount = 0;
      }
    }
  },

  props: {
    action: {
      type: Object as () => MoneyCheck,
      required: true
    }
  }
});
