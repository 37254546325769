
import { defineComponent } from "vue";
import { Text } from "@/utils/components/Text";
import FontEditor from "@/components/FontEditor.vue";
import ColorInput from "../ColorInput.vue";

export default defineComponent({
  data() {
    return {};
  },

  components: { FontEditor, ColorInput },

  props: {
    component: {
      type: Object as () => Text,
      required: true
    }
  },

  watch: {
    component: {
      deep: true,
      handler() {
        this.ensureValues();
      }
    }
  },

  methods: {
    ensureValues() {
      if (this.component.x == undefined) this.component.x = 0;
      if (this.component.y == undefined) this.component.y = 0;
      if (this.component.size == undefined) this.component.size = 0;
    }
  }
});
