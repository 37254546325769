
import { defineComponent } from "vue";
import { eraseCookie, getCookie, setCookie } from "../utils/CookieUtils";
import {
  licenseKey,
  licensePromptDoneAction
} from "../utils/manager/ProjectManager";
import { vueRef } from "../utils/VueRef";

export default defineComponent({
  data() {
    return {
      licenseKey: vueRef(licenseKey),
      remember: false,
      licensePromptDoneAction: vueRef(licensePromptDoneAction),
      savedKey: null as null | string
    };
  },

  mounted() {
    this.savedKey = getCookie("license-key");
    if (this.savedKey) {
      this.licenseKey = this.savedKey;
      this.remember = true;
    }
  },

  methods: {
    saveKey() {
      if (!this.licenseKey) return;

      if (
        this.remember &&
        this.licenseKey &&
        this.licenseKey != this.savedKey
      ) {
        setCookie("license-key", this.licenseKey, 10 * 365);
      }

      if (!this.remember && this.savedKey) {
        eraseCookie("license-key");
        this.savedKey = "";
      }

      this.licensePromptDoneAction!();
      this.licensePromptDoneAction = null;
    }
  }
});
