import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  class: "absoluteMenu",
  ref: "menu"
}
const _hoisted_2 = {
  key: 0,
  class: "divider"
}
const _hoisted_3 = { class: "material-icons" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.entries, (entry, index) => {
      return (_openBlock(), _createBlock("div", { key: index }, [
        (index != 0)
          ? (_openBlock(), _createBlock("div", _hoisted_2))
          : _createCommentVNode("", true),
        _createVNode("div", {
          class: "entry",
          onClick: _withModifiers(entry.action, ["stop"])
        }, [
          _createVNode("span", _hoisted_3, _toDisplayString(entry.icon), 1),
          _createTextVNode(" " + _toDisplayString(entry.name), 1 /* TEXT */)
        ], 8, ["onClick"])
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ], 512))
}