import { createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock("div", {
    id: "canvasPadding",
    onMousedown: _cache[1] || (_cache[1] = _withModifiers((...args) => (_ctx.onClickDown(...args)), ["prevent"])),
    onMouseup: _cache[2] || (_cache[2] = (...args) => (_ctx.onClickUp(...args))),
    onMousemove: _cache[3] || (_cache[3] = (...args) => (_ctx.onMove(...args))),
    onMouseleave: _cache[4] || (_cache[4] = (...args) => (_ctx.onClickUp(...args)))
  }, [
    _createVNode("canvas", {
      ref: "canvas",
      id: "canvas",
      width: _ctx.width,
      height: _ctx.height
    }, null, 8, ["width", "height"])
  ], 32))
}