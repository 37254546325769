
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {};
  },

  props: {
    modelValue: {
      type: Boolean
    },
    closeBtn: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String
    },
    title: {
      type: String
    }
  }
});
