
import { defineComponent } from "vue";
import { List } from "@/utils/components/List";

export default defineComponent({
  data() {
    return {};
  },

  props: {
    component: {
      type: Object as () => List,
      required: true
    }
  }
});
