
import { defineComponent } from "vue";
import { Replica } from "@/utils/components/Replica";
import { components, TemplateData } from "@/utils/manager/ComponentManager";
import { idWatcher } from "@/utils/manager/WorkspaceManager";
import { vueRef } from "../../utils/VueRef";

export default defineComponent({
  data() {
    return {
      components,
      defaultData: undefined as undefined | TemplateData,
      idWatcher: vueRef(idWatcher)
    };
  },

  props: {
    component: {
      type: Object as () => Replica,
      required: true
    }
  },

  mounted() {
    this.defaultData = this.component.getTemplateDefaultData();
  },

  watch: {
    defaultData: {
      deep: true,
      handler() {
        this.updateTemplateData();
      }
    },
    "component.targetId": {
      handler() {
        this.defaultData = this.component.getTemplateDefaultData();
      }
    },
    "component.id": {
      handler() {
        this.defaultData = this.component.getTemplateDefaultData();
        this.updateTemplateData();
      }
    }
  },

  methods: {
    updateTemplateData() {
      if (!this.defaultData) return;

      for (const entry of this.defaultData) {
        if (
          !this.component.templateData.some(
            entr =>
              entr.name == entry.name && typeof entr.value == typeof entry.value
          )
        ) {
          this.component.templateData.push(JSON.parse(JSON.stringify(entry)));
        }
      }

      this.component.templateData = this.component.templateData.filter(entry =>
        this.defaultData!.some(
          entr =>
            entr.name == entry.name && typeof entr.value == typeof entry.value
        )
      );
    }
  }
});
