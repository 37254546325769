
import { defineComponent } from "vue";
import { View } from "@/utils/components/View";

export default defineComponent({
  data() {
    return {};
  },

  props: {
    component: {
      type: Object as () => View,
      required: true
    }
  }
});
