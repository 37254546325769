
import { defineComponent } from "vue";
import { ItemCheck } from "@/utils/checks/ItemCheck";
import { Template } from "@/utils/components/Template";

export default defineComponent({
  data() {
    return {
      inputTransformer: Template.inputTransformer
    };
  },

  watch: {
    action: {
      deep: true,
      handler(action) {
        if (action.amount === "") this.action.amount = 0;
      }
    }
  },

  props: {
    action: {
      type: Object as () => ItemCheck,
      required: true
    }
  }
});
