
import { defineComponent } from "vue";
import { CheckComponent } from "@/utils/components/CheckComponent";
import { checks, checkIDs } from "../../utils/manager/CheckManager";
import { error } from "../../utils/manager/WorkspaceManager";

export default defineComponent({
  data() {
    return {
      checks,
      checkIDs,
      error
    };
  },

  props: {
    component: {
      type: Object as () => CheckComponent,
      required: true
    }
  }
});
