
import { defineComponent } from "vue";
import { PermissionCheck } from "@/utils/checks/PermissionCheck";

export default defineComponent({
  data() {
    return {};
  },

  props: {
    action: {
      type: Object as () => PermissionCheck,
      required: true
    }
  }
});
