
import { defineComponent } from "vue";
import { regFonts, registerFont } from "../utils/manager/FontManager";

export default defineComponent({
  data() {
    return { regFonts };
  },

  props: {
    font: String,
    size: Number
  },

  methods: {
    triggerFileSelector() {
      (this.$refs.fileDownload as HTMLElement).click();
    },

    checkForUpload() {
      const selector = this.$refs.fileDownload as HTMLInputElement;

      if (selector.files?.length) {
        const file = selector.files[0];
        registerFont(file, file.name.substr(0, file.name.length - 4));
      }
    }
  }
});
