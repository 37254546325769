
import { defineComponent } from "vue";
import { loadingState } from "../utils/manager/WorkspaceManager";

export default defineComponent({
  data() {
    return {
      loading: loadingState
    };
  }
});
