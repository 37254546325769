
import { defineComponent } from "vue";
import { Hover } from "@/utils/components/Hover";

export default defineComponent({
  data() {
    return {};
  },

  props: {
    component: {
      type: Object as () => Hover,
      required: true
    }
  }
});
