
import { defineComponent } from "vue";
import { getCookie, setCookie } from "../utils/CookieUtils";
import { settings } from "../utils/manager/SettingsManager";
import {
  serverAddress,
  syncPromptOpen,
  syncStatus,
  syncKey,
  syncType,
  SyncType,
  pingServer
} from "../utils/manager/SyncManager";
import { error, loading } from "../utils/manager/WorkspaceManager";
import { vueRef } from "../utils/VueRef";

export default defineComponent({
  data() {
    return {
      serverAddress: vueRef(serverAddress),
      syncPromptOpen: vueRef(syncPromptOpen),
      syncStatus: vueRef(syncStatus),
      syncKey: vueRef(syncKey),
      syncType: vueRef(syncType),
      settings,
      SyncType
    };
  },

  mounted() {
    this.serverAddress = getCookie("server-address") || null;
  },

  computed: {
    syncCommand(): string {
      return `/ag sync ${this.syncType == SyncType.MANUAL ? "-manual " : ""}${
        this.syncKey
      } ${this.settings.projectName}`;
    }
  },

  methods: {
    async sync() {
      setCookie("server-address", this.serverAddress!, 10 * 365);
      setCookie("sync-type", this.syncType!.toString(), 10 * 365);
      loading(true);
      try {
        await pingServer();
        syncPromptOpen.value = false;
        loading(false);
      } catch (exc) {
        error(exc.message || exc);
      }
    },

    copyCommand() {
      const input = this.$refs.commandInput as HTMLInputElement;
      const icon = this.$refs.copyCommandIcon as HTMLElement;

      input.select();
      input.setSelectionRange(0, 99999);
      document.execCommand("copy");

      icon.innerText = "assignment_turned_in";

      setTimeout(() => (icon.innerText = "content_copy"), 1000);
    }
  }
});
