
import { defineComponent } from "vue";
import { DelayAction } from "@/utils/actions/DelayAction";
import { Template } from "@/utils/components/Template";

export default defineComponent({
  data() {
    return {
      inputTransformer: Template.inputTransformer
    };
  },

  props: {
    action: {
      type: Object as () => DelayAction,
      required: true
    }
  }
});
